<template>
    <li
        class="nav-item nav-drop-item items account"
        :class="{ isLogged: isLogged, hasAvatar: avatar != '' }"
        @mouseover="openPopup()"
        @mouseleave="closePopup()"
    >
        <router-link
            v-if="isLogged"
            v-slot="{ navigate }"
            :to="{ name: 'ContulMeu' }"
            custom
        >
            <b-button
                variant="text"
                class="icon link"
                aria-label="contul meu"
                @click="navigate"
            >
                <user-icon v-if="!isLogged || avatar == ''" />
                <div
                    v-if="isLogged && avatar != ''"
                    class="
                    profile-image"
                >
                    <img
                        :src="avatar"
                        alt="avatar"
                    >
                </div>
            </b-button>
        </router-link>
        <b-button
            v-else
            variant="text"
            class="icon link"
            aria-label="contul meu"
        >
            <user-icon v-if="avatar == ''" />
            <div
                v-else
                class="profile-image"
            >
                <img
                    :src="avatar"
                    alt="avatar"
                >
            </div>
        </b-button>
        <div
            v-if="showPopup"
            class="navbar-dropdown auth-nav"
        >
            <div class="drop-content">
                <ul>
                    <li
                        v-if="isLogged"
                        class="nav-item hello"
                    >
                        {{ $t('header.navbar.hello') }}
                        {{ generalData.first_name }}
                    </li>
                    <router-link
                        v-if="isLogged"
                        v-slot="{ navigate }"
                        :to="{ name: 'ContulMeu' }"
                        custom
                    >
                        <b-nav-item @click="navigate">
                            {{ $t('header.navbar.myAccount') }}
                        </b-nav-item>
                    </router-link>
                    <b-nav-item
                        v-else
                        @click="showLoginPopup()"
                    >
                        {{ $t('header.navbar.login') }}
                    </b-nav-item>
                    <router-link
                        v-if="isLogged"
                        v-slot="{ navigate }"
                        :to="{ name: 'Comenzi' }"
                        custom
                    >
                        <b-nav-item @click="navigate">
                            {{ $t('header.navbar.myOrders') }}
                        </b-nav-item>
                    </router-link>
                    <b-nav-item
                        v-if="!isLogged"
                        @click="showRegistrationPopup()"
                    >
                        {{ $t('header.navbar.registration') }}
                    </b-nav-item>
                    <b-nav-item
                        v-if="isLogged"
                        @click="showLogoutPopup()"
                    >
                        {{ $t('header.navbar.logout') }}
                    </b-nav-item>
                </ul>
            </div>
        </div>
    </li>
</template>

<script>
    import UserIcon from 'vue-feather-icons/icons/UserIcon';
    import { mapGetters, mapMutations, mapState } from 'vuex';

    export default {
        name: 'DesktopNavbar',
        components: {
            UserIcon,
        },
        data() {
            return {
                showPopup: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['isLogged']),
            ...mapState('user', ['generalData', 'avatar']),
        },

        methods: {
            ...mapMutations('general', ['setModal']),
            showLoginPopup() {
                this.setModal({
                    show: true,
                    type: 'login'
                });
            },
            showLogoutPopup() {
                this.setModal({
                    show: true,
                    type: 'logout'
                });
            },
            showRegistrationPopup() {
                this.setModal({
                    show: true,
                    type: 'registration'
                });
            },
            closePopup() {
                this.showPopup = false;
            },
            openPopup() {
                this.showPopup = true;
            },
        },
        watch: {
            $route() {
                this.showPopup = false;
            },
        },
    };
</script>
